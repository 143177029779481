import Customer from "./views/customer/CustomersPage.js";
import Dashboard from "./views/dashboard/DashboardPage.js";
import Subscription from "./views/subscription/SubscriptionPage.js";
import Profile from "./views/setting/ProfilePage.js";
import Login from "./views/login/LoginPage.js";
import LoginStripePortal from "./views/login/LoginStripePortal.js";
import LoginLinkVerify from "./views/login/LoginLinkVerifyPage.js";
import OrgFinder from "./views/login/OrgFinderPage.js";
import Logout from "./views/logout/logout.js";
import PasswordReset from "./views/login/PasswordResetPage.js";
import PasswordSet from "./views/login/PasswordSetPage.js";
import Register from "./views/register/RegisterPage";
import EmailVerification from "./views/register/EmailVerification";
import ChangePlansPage from "./views/plans/ChangePlansPage";
import ChangePlansPreviewPage from "./views/plans/ChangePlansPreviewPage";
import NotFound from "./views/notfound/NotFound";

const routes = [
  {
    path: "/dashboard",
    exact: true,
    // isProtected: true,
    Component: Dashboard,
  },
  {
    path: "/customers",
    exact: true,
    isProtected: true,
    Component: Customer,
  },
  {
    path: "/profile",
    exact: true,
    isProtected: true,
    Component: Profile,
  },
  {
    path: "/subscription/:customerId/:subscriptionId",
    exact: true,
    isProtected: true,
    Component: Subscription,
  },
  {
    path: "/subscription/:customerId/:subscriptionId/plans",
    exact: true,
    isProtected: true,
    Component: ChangePlansPage,
  },
  {
    path: "/subscription/:customerId/:subscriptionId/plans/preview/:planId",
    exact: true,
    isProtected: true,
    Component: ChangePlansPreviewPage,
  },
  {
    path: "/:org/register",
    exact: true,
    isProtected: false,
    Component: Register,
  },
  {
    path: "/verify/:user",
    exact: true,
    isProtected: false,
    Component: EmailVerification,
  },
  {
    path: "/:org/signup",
    exact: true,
    isProtected: false,
    Component: Register,
  },
  {
    path: "/",
    exact: true,
    isProtected: false,
    Component: OrgFinder,
  },
  {
    path: "/login",
    exact: true,
    isProtected: false,
    Component: OrgFinder,
  },
  {
    path: "/:org/login",
    exact: true,
    isProtected: false,
    Component: Login,
  },
  {
    path: "/:org/secure/login",
    exact: true,
    isProtected: false,
    Component: LoginStripePortal,
  },
  {
    path: "/:org/login/link",
    exact: true,
    isProtected: false,
    Component: LoginLinkVerify,
  },
  {
    path: "/:org/login/link/:linkId",
    exact: true,
    isProtected: false,
    Component: LoginLinkVerify,
  },
  {
    path: "/:org/password/reset",
    exact: true,
    isProtected: false,
    Component: PasswordReset,
  },
  {
    path: "/:org/password/set/:token",
    exact: true,
    isProtected: false,
    Component: PasswordSet,
  },
  {
    path: "/logout",
    exact: true,
    isProtected: false,
    Component: Logout,
  },
];

export default routes;
