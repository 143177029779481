import React, { useState, useEffect } from "react";
import axios from "axios";
import NavBar from "../../components/navigation/NavBar";
import { makeStyles } from "@material-ui/core/styles";
import cogoToast from "cogo-toast";
import {
  Paper,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Button,
  Divider,
  Typography
} from "@material-ui/core";

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundColor: "#fafafa"
  },
  card: {
    //maxWidth: 345,
    width: "100%"
  },
  paper: {
    marginBottom: "3%",
    padding: "1%"
  },
  planPaper: {
    marginBottom: "3%",
    padding: "3%",
    width: "100%",
    minHeight: 200
  },
  media: {
    height: 140
  }
});

function ChangePlansPreviewPage(props) {
  const { ...other } = props;
  // Declare a new state variable, which we'll call "count"
  const classes = useStyles();
  const [state, setState] = useState({ products: [] });
  const [sub, setSub] = useState({});
  const [customer, setCustomer] = useState({});
  const [activePlans, setActivePlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadProducts();
    fetchSub();
  }, []);

  const fetchSub = () => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/user/subscriptions/${props.match.params.customerId}/${props.match.params.subscriptionId}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        let filteredSub = response.data.subscriptions.data.find(
          sub => sub.id === props.match.params.subscriptionId
        );
        let filteredPlans = filteredSub.items.data.map(item => item.plan.id); //strips out the plan ids on the sub
        setActivePlans(filteredPlans);
        setSub(filteredSub); //the filtered sub
        setCustomer(response.data);
        setIsLoading(false);
      })
      .catch(function(error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const loadProducts = () => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/org/products/all`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        setState({
          ...state,
          products: response.data
        });
        setIsLoading(false);
      })
      .catch(function(error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const formatPlanText = (amount, interval, interval_count) => {
    const amountText = (amount / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
    return `${amountText} every ${interval_count} ${interval}`;
  };

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const normalInvoice = {};

  const upgradedInvoice = {};

  return (
    <div className={classes.root}>
      <NavBar {...other} />
      <Grid container justify="center" styles={{ paddingTop: 500 }}>
        <Grid item xs={12} md={8}>
          {/* <Paper className={classes.paper}> */}
          <Grid
            container
            justify="center"
            spacing={2}
            alignContent="center"
            alignItems="center"
          >
            {isLoading ? "Loading" : "Invocie"}
          </Grid>
          {/* </Paper> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default ChangePlansPreviewPage;
