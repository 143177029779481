import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import { Helmet } from "react-helmet";
import NotFound from "./views/notfound/NotFound";
import "./App.css";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import routes from "./routes";
function loggedIn() {
  console.log("loggedIn() was called");
  let token = localStorage.getItem("token");
  let timestamp = localStorage.getItem("timestamp");
  let potentialExp = timestamp / 1000 + 7200;
  if (
    token != null &&
    timestamp != null &&
    potentialExp > new Date().getTime() / 1000
  ) {
    console.log(true, "the user met logged in condition");
    return true;
  } else {
    console.log(false, " the user did NOT meet logged in condition");
    return false;
  }
}

const theme = createMuiTheme({
  palette: {
    /*  primary: {
      light: '#8551fd',
      main: '#814ff8',
      dark: '#051550',
      contrastText: '#fff',
    },*/
    primary: {
      light: localStorage.getItem("primary_color") || "#586ada",
      main: localStorage.getItem("primary_color") || "#586ada",
      dark: localStorage.getItem("primary_color") || "#586ada",
      contrastText: localStorage.getItem("text_color") || "#fff",
    },
    secondary: {
      light: localStorage.getItem("primary_color") || "#586ada",
      main: localStorage.getItem("primary_color") || "#586ada",
      dark: localStorage.getItem("primary_color") || "#586ada",
      contrastText: localStorage.getItem("text_color") || "#fff",
    },
  },
  /*overrides: {
   // Name of the component ⚛️ / style sheet
   MuiButton: {
     // Name of the rule
     root: {
       // Some CSS
       background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
       borderRadius: 3,
       border: 0,
       color: 'white',

       boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
     },
   },
 },*/
});

const PrimaryLayout = (props) => (
  <div className="primary-layout">
    <main>
      <Helmet>
        <title>
          {localStorage.getItem("business_name") === "undefined"
            ? "InstaPayments"
            : localStorage.getItem("business_name")}
        </title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Switch>
        {routes.map(({ isProtected, path, Component }) =>
          isProtected ? (
            <Route
              key={path}
              path={path}
              exact
              render={(passedProps) =>
                !loggedIn() ? (
                  <Redirect to={`/login`} />
                ) : (
                  <Component {...passedProps} />
                )
              }
            />
          ) : (
            <Route path={path} exact component={Component} />
          )
        )}
        <Route exact component={NotFound} />
      </Switch>
    </main>
  </div>
);

const App = () => (
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <PrimaryLayout />
    </MuiThemeProvider>
  </BrowserRouter>
);

export default App;
