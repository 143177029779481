import Paper from '@material-ui/core/Paper';
import React from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import queryString from 'qs';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  root:{
    marginTop:15,
    width: "50%",
    marginLeft: "25%"
  },
  background:{
    //backgroundColor: "grey"
  },
  paper: theme.mixins.gutters({
    paddingTop: 20,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  container: {
   display: 'flex',
   flexWrap: 'wrap',
 },
 textField: {
   marginLeft: theme.spacing.unit,
   marginRight: theme.spacing.unit,
 },
 textFieldHalf:{
   marginLeft: theme.spacing.unit * .5,
   marginRight: theme.spacing.unit * .5,
   width: "48%"
 },
 button: {
   //width:00,
    margin: theme.spacing.unit,
  },
  bigAvatar: {
  width: 100,
  height: 100,
  },
 menu: {
   width: 100,
 },
});



class Register extends React.Component {
  constructor(props){
    super(props);
    this.state={
      password:'',
      username: '',
      messageError:'',
      messageSuccess:''
    }
  }

  handleChange = name => event => {
   this.setState({
     [name]: event.target.value,
   });
 };

 handleSnackClose = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   this.setState({ snackOpen: false });
 };

   componentDidMount(event){
     var self = this;
     const query = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })

     console.log(query)
     if (query.email) {
       self.setState({
         username: query.email
       })
     }
     axios.get(`${apiBaseUrl}/v1/org/${self.props.match.params.org}/brand`)
     .then(function (response) {
       console.log(response);
       if(response.status === 200 || response.status === 304){

           localStorage.setItem('logo',response.data.branding.logo );
           localStorage.setItem('url',response.data.branding.url );
           localStorage.setItem('primary_color', response.data.branding.primary_color);
           localStorage.setItem('business_name', response.data.branding.business_name);
           localStorage.setItem('allow_cancelations', response.data.permissions.allow_cancelations);
       }
     }).catch((err)=>{
       console.log("Error retirving org")
       let path = `/login`
       self.props.history.push(path);
     })
   }


  handleClick(event){
    console.log(this.state)
    var self = this;

    var payload={
      "first_name":this.state.first_name,
      "last_name":this.state.last_name,
      "phone":this.state.phone,
      "username":this.state.username,
      "email":this.state.username,
      "password":this.state.password,
    }
    if(!payload.username || !payload.password){
      self.setState({messageError:"These fields are required"});
    }else{
    axios.post(`${apiBaseUrl}/v1/user/${self.props.match.params.org}/new`, payload)
    .then(function (response) {
      console.log(response);
      if(response.status === 200){
        self.setState({
          snackOpen: true,
          snackVariant:"success",
          snackMessage:"User was created successfully."
        });
          let path = `/${self.state.org_handle}/login`
          self.props.history.push(path);
      }else{
        let path = `/register`
        self.props.history.push(path);
      }})
    .catch(function (error) {
      console.log("error", error.response);
      if (error.response.status === 409) {
        self.setState({
          snackOpen: true,
          snackVariant:"error",
          snackMessage:"A user with that email already exists."
        });
      }else{
        self.setState({
          snackOpen: true,
          snackVariant:"error",
          snackMessage: error.response.data.errorsArray[0].msg
        });
      }
    });
  }
}

  render() {
    const { classes } = this.props;
    return (
    <div className={classes.background}>
    <Grid container className={classes.root} justify={"center"}>

      <Paper className={classes.paper} elevation={4}>
      <Grid container justify="center">
      <div style={{maxHeight: "3%", marginTop: "1%"}}>
      {localStorage.getItem('logo') ?
        <img src={localStorage.getItem('logo')} style={{maxHeight: "120px"}} alt="logo"/>
        :
        <img src={"/images/icon.png"} style={{maxHeight: "120px"}} alt="logo"/>

      }
      </div>
      </Grid>

      <form className={classes.container} noValidate autoComplete="off">

        <Grid container justify={"center"}>
        <TextField
        label="First Name"
        value={this.state.first_name}
        className={classes.textFieldHalf}
        helperText="Enter your first name"
        onChange={this.handleChange('first_name')}
        />

        <TextField
        label="Last Name"
        value={this.state.last_name}
        className={classes.textFieldHalf}
        helperText="Enter your last name"
        onChange={this.handleChange('last_name')}
        />
        </Grid>
        <Grid container justify={"center"}>
        <TextField
        label="Email"
        fullWidth
        value={this.state.username}
        className={classes.textFieldHalf}
        helperText="Enter your username"
        onChange={this.handleChange('username')}
        />
        <TextField
        label="Phone"
        fullWidth
        value={this.state.phone}
        className={classes.textFieldHalf}
        helperText="Enter your phone number"
        onChange={this.handleChange('phone')}
        />
        </Grid>

        <TextField
        label="Password"
        fullWidth
        type="password"
        className={classes.textField}
        helperText="Enter your password"
        onChange={this.handleChange('password')}
        />
        <br />
        <Grid container justify={"center"}>
        <Button
        variant="contained"
        color="primary"
        fullWidth="true"
        className={classes.button}
        onClick={(event) => this.handleClick(event)}
        >
        Register
        </Button>
        </Grid>

        </form>
      </Paper>


    </Grid>
    <Snackbar
       anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'center',
       }}
       open={this.state.snackOpen}
       autoHideDuration={3000}
       onClose={this.handleSnackClose}
       >
         <MySnackbarContentWrapper
         variant={this.state.snackVariant}
         message={this.state.snackMessage}
         onClose={this.handleSnackClose}
        />
    </Snackbar>
    </div>
  );
}
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Register);
