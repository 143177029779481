import Paper from "@material-ui/core/Paper";
import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  root: {
    marginTop: "10%"
  },
  background: {
    //backgroundColor: "grey"
  },
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 1
  }),
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  button: {
    //width:00,
    margin: theme.spacing.unit
  },
  bigAvatar: {
    width: 100,
    height: 100,
    backgroundColor: "#fff"
  },
  menu: {
    width: 200
  }
});

class LoginLinkVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "test",
      password: "",
      messageError: "",
      usernameMessage: "Enter the email you signed up with.",
      passwordMessage: "Enter your password",
      usernameInvalid: false,
      passwordInvalid: false
    };
  }

  componentDidMount(event) {
    var self = this;
    var org_handle = self.props.match.params.org;
    if (org_handle === undefined) {
      org_handle = localStorage.getItem("org");
    }
    axios
      .get(`${apiBaseUrl}/v1/org/${org_handle}/brand`)
      .then(function(response) {
        if (response.status === 200 || response.status === 304) {
          self.setState({
            ...response.data.branding
          });

          localStorage.setItem(
            "logo",
            response.data.branding.logo || "/images/icon.png"
          );
          localStorage.setItem("url", response.data.branding.url);
          localStorage.setItem(
            "primary_color",
            response.data.branding.primary_color
          );
          localStorage.setItem(
            "business_name",
            response.data.organization_name
          );
          localStorage.setItem(
            "allow_cancelations",
            response.data.permissions.allow_cancelations
          );
          localStorage.setItem("org", self.props.match.params.org);
        }
      })
      .catch(err => {
        console.log("Error retirving org", err);
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage: `Could not load branding. Be sure ${self.props.match.params.org} is the place you want to log into.`
        });
      });
    if (self.props.match.params.linkId) {
      //won't attempt the call unless that param is present
      axios
        .get(
          `${apiBaseUrl}/v1/user/login/link/${self.props.match.params.linkId}`
        )
        .then(function(response) {
          if (response.status === 200) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("timestamp", new Date().getTime());
            localStorage.setItem(
              "name",
              `${response.data.first_name} ${response.data.last_name}`
            );
            localStorage.setItem("first_name", response.data.first_name);
            localStorage.setItem("last_name", response.data.last_name);
            localStorage.setItem("_id", response.data._id);
            localStorage.setItem(
              "gravatar",
              `https://www.gravatar.com/avatar/${response.data.gravatar}?d=mp`
            );
            localStorage.setItem("org", response.data.org_handle);
            console.log(response.data.org_handle);
            let path = `/customers`;
            if (response.data.first_name == null) {
              path = "/profile";
            }
            self.props.history.push(path);
          }
        })
        .catch(err => {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: `Error logging you in with that link. That link may be expired or already used.`
          });
        });
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackOpen: false });
  };

  handleClick(event) {
    console.log(this.state);
    event.preventDefault();
    var self = this;

    var payload = {
      email: this.state.email.toLowerCase().trim()
    };
    axios
      .post(
        `${apiBaseUrl}/v1/user/${self.props.match.params.org}/login/link`,
        payload
      )
      .then(function(response) {
        if (response.status === 200) {
          self.setState({
            snackOpen: true,
            snackVariant: "success",
            snackMessage: "Check your email for your login link."
          });
        }
      })
      .catch(function(error) {
        console.log(error.response.data.message);
        let errorMessage =
          "Error generating your email. Be sure you are attempting to login to the correct org.";
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage: errorMessage
        });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.background}>
        <Grid container className={classes.root} justify={"center"}>
          <Paper className={classes.paper} elevation={4}>
            <Grid container justify="center">
              <div style={{ maxHeight: "3%", marginTop: "1%" }}>
                {localStorage.getItem("logo") ? (
                  <img
                    src={localStorage.getItem("logo")}
                    style={{ maxHeight: "120px" }}
                    alt="logo"
                  />
                ) : (
                  <img
                    src={this.state.logo}
                    style={{ maxHeight: "120px" }}
                    alt="logo"
                  />
                )}
              </div>
            </Grid>
            <Grid container justify="center">
              <p>
                {" "}
                You will be able to set a password if you haven't logged in
                before.{" "}
              </p>
            </Grid>

            <form
              className={classes.container}
              onSubmit={event => this.handleClick(event)}
            >
              <TextField
                label="Email"
                fullWidth
                required
                error={this.state.usernameInvalid}
                className={classes.textField}
                helperText="Enter the email you signed up with."
                onChange={this.handleChange("email")}
              />

              <Grid container justify={"center"}>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth="true"
                  style={{
                    backgroundColor: this.state.primary_color || undefined
                  }}
                  className={classes.button}
                  type="submit"
                >
                  Login with Email
                </Button>
              </Grid>
            </form>

            <a
              style={{ fontSize: 12, paddingLeft: 10 }}
              href={`/${this.props.match.params.org}/login`}
            >
              Login with your password?
            </a>
          </Paper>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </div>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(LoginLinkVerify);
