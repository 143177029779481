import React, { useState, useEffect } from "react";
import axios from "axios";
import { ArrowForward } from "@material-ui/icons";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useWindowSize } from "react-use";
import MuiTableCell from "@material-ui/core/TableCell";
import { format } from "date-fns";
import { withStyles } from "@material-ui/core/styles";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = (theme) => ({
  leftSection: {
    backgroundColor: "#f4f5f8",
    padding: 20,
    height: "100vh",
    overflowY: "hidden",
    [theme.breakpoints.down("md")]: {
      height: "20vh",
    },
  },

  rightSection: {
    boxShadow: "-5px 0px 6px 0px #e4e6ea",
  },
  profilesSection: {
    marginTop: "20%",
  },

  profileTable: {
    marginBottom: 20,
  },
  dividers: {
    marginTop: 15,
    marginBottom: 15,
  },
});

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: 5,
    align: "center",
  },
})(MuiTableCell);

const Dashboard = (props) => {
  const { classes } = props;
  const [invoices, setInvoices] = useState([]);
  const [charges, setCharges] = useState([]);
  const [user, setUser] = useState(null);
  const [customers, setCustomers] = useState([]);

  const { width, height } = useWindowSize();
  const isMobile = width < 600;
  const fetchInvoices = (customerId) =>
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/user/invoices/${customerId}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });

  const fetchCharges = (customerId) =>
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/user/invoices/charges/${customerId}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });

  const fetchUser = () =>
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/user/me?customers=true&account=true`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });

  const handleBillingPortal = async (customerId) => {
    let { data: session } = await axios({
      method: "post",
      url: `${apiBaseUrl}/v1/user/sessions/${customerId}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      data: {
        redirectUrl: `https://users.instapayments.io/dashboard`,
      },
    });
    window.open(session.url, "_blank").focus();
  };

  const handleInitialFetch = async () => {
    let { data: user } = await fetchUser();
    console.log("handleInitialFetch was called", user);
    setUser(user);
    setCustomers(user.customers);
    let invoiceRequests = user.customers.map((customer) =>
      fetchInvoices(customer.id)
    );
    let chargeRequests = user.customers.map((customer) =>
      fetchCharges(customer.id)
    );
    let invoiceResponses = await Promise.all(invoiceRequests);
    let chargeResponses = await Promise.all(chargeRequests);
    let formattedInvoices = [];
    let formattedCharges = [];

    for (const invoiceResponse of invoiceResponses) {
      formattedInvoices.push(...invoiceResponse.data.data);
    }
    for (const chargeResponse of chargeResponses) {
      formattedCharges.push(...chargeResponse.data.data);
    }
    setInvoices(formattedInvoices);
    setCharges(formattedCharges);
  };
  useEffect(() => {
    handleInitialFetch();
  }, []);

  return (
    <Grid
      container
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container item xs={12} md={3} className={classes.leftSection}>
        <Grid xs={12}>
          {user?.account?.icon && (
            <img
              key={new Date().getTime()}
              src={user?.account?.icon}
              alt="Logo"
            />
          )}
          {/* <Typography>Back to Site</Typography> */}
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        style={{ padding: 20 }}
        className={classes.rightSection}
      >
        <Grid item xs={12} md={10} className={classes.profilesSection}>
          <Typography variant="h5">Profiles</Typography>
          <Typography variant="caption">
            Click <i>manage</i> on a profile below to update your card,
            subscription, shipping address and more.
          </Typography>
          <Divider className={classes.dividers} />
          <Table
            className={classes.profileTable}
            size="small"
            padding="none"
            align="center"
          >
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                {!isMobile && <TableCell>Account Balance</TableCell>}
                {!isMobile && <TableCell>Subscription Count</TableCell>}
                {!isMobile && <TableCell>Created At</TableCell>}
                <TableCell>Manage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map(
                ({ id, name, email, subscriptions, created, balance }) => (
                  <TableRow key={id} style={{ height: "auto !important" }}>
                    <TableCell
                      size="small"
                      padding="none"
                      style={{ maxWidth: 175 }}
                    >
                      {email}
                    </TableCell>
                    {!isMobile && (
                      <TableCell>
                        {(balance / 100).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </TableCell>
                    )}
                    {!isMobile && (
                      <TableCell>{subscriptions?.total_count || 0}</TableCell>
                    )}

                    {!isMobile && (
                      <TableCell>
                        {created && format(new Date(created), "MM/dd/yyyy")}
                      </TableCell>
                    )}

                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleBillingPortal(id)}
                        size="small"
                        endIcon={<ArrowForward />}
                      >
                        Manage
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Billing History</Typography>
          <Typography variant="caption">
            Click <i>view</i> on a transaction below to see the full invoice or
            receipt.
          </Typography>
          <Divider className={classes.dividers} />
          <Table className={classes.invoiceTable}>
            <TableHead>
              <TableRow>
                <TableCell numeric>Date</TableCell>
                <TableCell numeric>Amount</TableCell>
                <TableCell numeric>Status</TableCell>
                <TableCell numeric>View/Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map(
                ({ id, amount_due, status, created, hosted_invoice_url }) => (
                  <TableRow key={id} style={{ height: "auto !important" }}>
                    <TableCell>
                      {created &&
                        format(new Date(created * 1000), "MM/dd/yyyy")}
                    </TableCell>
                    <TableCell>
                      {(amount_due / 100).toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </TableCell>
                    <TableCell>{status}</TableCell>

                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={hosted_invoice_url}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}
              {charges.map(
                ({ id, amount, status, link, created, receipt_url }) => (
                  <TableRow key={id} style={{ height: "auto !important" }}>
                    <TableCell>
                      {created &&
                        format(new Date(created * 1000), "MM/dd/yyyy")}
                    </TableCell>
                    <TableCell>
                      {(amount / 100).toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </TableCell>
                    <TableCell>{status}</TableCell>

                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        href={receipt_url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Dashboard);
