import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import _ from "lodash";

const styles = theme => ({
  flex: {
    flex: 1
  },
  logo: {
    padding: "10px",
    backgroundColor: "#fff",
    maxWidth: "50px",
    maxHeight: "50px",
    borderRadius: "20%"
  }
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      active: "",
      anchorMenuEl: null
    };
  }

  changeNav = name => event => {
    let path = `/${name}`;
    this.props.history.push(path);
  };

  handleMenu = event => {
    this.setState({ anchorMenuEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorMenuEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorMenuEl } = this.state;
    const open = Boolean(anchorMenuEl);
    return (
      <AppBar position="relative" style={{ marginBottom: "30px" }}>
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.flex}
          >
            <img
              className={classes.logo}
              alt="logo"
              src={localStorage.getItem("logo")}
            />
          </Typography>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.flex}
          >
            {_.startCase(_.camelCase(localStorage.getItem("business_name")))}
          </Typography>

          <Typography variant="subheading" color="inherit" noWrap>
            Hi{" "}
            {localStorage.getItem("first_name") === "undefined"
              ? ""
              : localStorage.getItem("first_name")}
          </Typography>
          <IconButton
            aria-owns={open ? "menu-appbar" : null}
            aria-haspopup="true"
            onClick={this.handleMenu}
            color="inherit"
            className={classes.avatar}
          >
            <Avatar
              aria-label="Avatar"
              className={classes.avatar}
              src={localStorage.getItem("gravatar")}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorMenuEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={open}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.changeNav("profile")}>Profile</MenuItem>
            <MenuItem onClick={this.changeNav("customers")}>
              Billing Manager
            </MenuItem>
            <MenuItem
              onClick={this.changeNav(`${localStorage.getItem("org")}/logout`)}
            >
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NavBar);
