import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar from "../../components/navigation/NavBar";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Moment from "react-moment";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

Moment.globalFormat = "MMM D YYYY";

const styles = theme => ({
  root: {
    minHeight: "100vh"
  },
  paperAppBar: {
    marginTop: "-1%",
    marginBottom: "1%"
  },
  button: {
    marginTop: theme.spacing.unit * 3
  },
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    marginTop: theme.spacing.unit * 2
  }),
  bigAvatar: {
    width: 100,
    height: 100
  }
});

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      newPassword: "",
      customers: [],
      snackOpen: false,
      snackVariant: "success",
      snackMessage: "Password has been updated"
    };
  }

  changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackOpen: false });
  };

  updateStripeCustomer = () => {
    if (this.state.customers.length === 0) {
      return;
    }
    var self = this;
    self.setState({
      updateUserFinished: false
    });
    for (let customer of this.state.customers) {
      axios({
        method: "PUT",
        url: `${apiBaseUrl}/v1/user/customers/${customer.id}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json"
        },
        data: {
          name: `${this.state.first_name} ${this.state.last_name}`,
          metadata: {
            phone: this.state.phone
          }
        }
      })
        .then(function(response) {
          console.log(response.data);
          if (response.status === 200) {
            self.setState({
              snackOpen: true,
              snackVariant: "success",
              snackMessage: "Your profile has been updated"
            });
            self.fetchUser();
          }
        })
        .catch(function(error) {
          console.log(error);
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: "Error updating profile."
          });
        });
    }
  };

  updateUser = () => {
    var self = this;
    self.setState({
      updateUserFinished: false
    });
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/user/profile`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      data: {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone
      }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          localStorage.setItem("first_name", response.data.first_name);
          localStorage.setItem("last_name", response.data.last_name);
          self.updateStripeCustomer();
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage: "Error updating profile."
        });
      });
  };

  updatePassword = () => {
    var self = this;
    self.setState({
      updateUserFinished: false
    });
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/v1/user/password`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      data: {
        password: this.state.newPassword
      }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          self.setState({
            updatePasswordFinished: true,
            newPassword: "",
            snackOpen: true,
            snackVariant: "success",
            snackMessage: "Password has been updated"
          });
          let path = `/customers`;
          self.props.history.push(path);

          //self.fetchUser()
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage: "Password too short"
        });
      });
  };

  fetchUser = () => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/user/me?customers=true`, //retrieves the current authenticated user
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        if (response.status === 200) {
          if (response.data.first_name == null) {
            self.setState({
              snackOpen: true,
              snackVariant: "info",
              snackMessage:
                "Looks like you're missing some info. Let's fill it in!"
            });
          }
          console.log(response);
          self.setState({
            user: response.data,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            email: response.data.email,
            phone: response.data.phone,
            finished: true,
            customers: response.data.customers
          });
        } else {
          let path = `/${self.state.org_handle}/login`;
          self.props.history.push(path);
        }
      })
      .catch(function(error) {
        console.log(error);
        self.setState({ messageError: "Error retrieving user profile" });
      });
  };

  componentDidMount(event) {
    this.fetchUser();
  }

  render() {
    console.log(this.state);
    const { classes } = this.props;

    return (
      <React.Fragment>
        <NavBar {...this.props} />

        <Grid container justify={"center"} spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper className={classes.paper} elevation={4}>
              <AppBar
                className={classes.paperAppBar}
                color="secondary"
                position="relative"
              >
                <Toolbar>
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.flex}
                  >
                    Profile
                  </Typography>
                </Toolbar>
              </AppBar>
              <form noValidate autoComplete="off">
                <Grid container spacing={16} justify={"center"}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="First Name"
                      fullWidth
                      value={this.state.first_name}
                      helperText="Enter your first name"
                      onChange={this.handleChange("first_name")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Last Name"
                      fullWidth
                      value={this.state.last_name}
                      className={classes.textFieldHalf}
                      helperText="Enter your last name"
                      onChange={this.handleChange("last_name")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Email"
                      fullWidth
                      value={this.state.email}
                      helperText="Enter your username"
                      onChange={this.handleChange("username")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Phone"
                      fullWidth
                      value={this.state.phone}
                      helperText="Enter your phone number"
                      onChange={this.handleChange("phone")}
                    />
                  </Grid>
                </Grid>

                <Grid container justify={"center"}>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={event => this.updateUser()}
                  >
                    Update Profile
                  </Button>
                </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper className={classes.paper} elevation={4}>
              <AppBar
                position="static"
                className={classes.paperAppBar}
                color="secondary"
              >
                <Toolbar>
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.flex}
                  >
                    Password
                  </Typography>
                </Toolbar>
              </AppBar>
              <form className={classes.container} noValidate autoComplete="off">
                <TextField
                  label="New Password"
                  id="margin-none"
                  fullWidth
                  type="password"
                  value={this.state.newPassword}
                  helperText="Enter your new password"
                  onChange={this.handleChange("newPassword")}
                />

                <Grid container justify={"center"}>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={event => this.updatePassword()}
                  >
                    Change/Set Password
                  </Button>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

//
/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Profile);
