import Paper from '@material-ui/core/Paper';
import React from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => (
  {
  root:{
    marginTop:"10%"
  },
  background:{
    //backgroundColor: "grey"
  },
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 1,
  }),
  container: {
   display: 'flex',
   flexWrap: 'wrap',
 },
 textField: {
   marginLeft: theme.spacing.unit,
   marginRight: theme.spacing.unit,
 },
 button: {
   //width:00,
    margin: theme.spacing.unit,
  },
  bigAvatar: {
  width: 100,
  height: 100,
  backgroundColor:"#fff"
  },
 menu: {
   width: 200,
 },
});


class Login extends React.Component {
  constructor(props){
    super(props);
    this.state={
      username:'test',
      password:'',
      messageError:'',
      usernameMessage:'Enter your username',
      passwordMessage:'Enter your password',
      usernameInvalid: false,
      passwordInvalid: false
    }
  }

componentDidMount(event){
  var self = this;
  var org_handle = self.props.match.params.org;
  if (org_handle === undefined) {
    org_handle = localStorage.getItem('org');
  }
  axios.get(`${apiBaseUrl}/v1/org/${org_handle}/brand`)
  .then(function (response) {
    if(response.status === 200 || response.status === 304){
      self.setState({
        ...response.data.branding
      })

        localStorage.setItem('logo',response.data.branding.logo || "/images/icon.png");
        localStorage.setItem('url',response.data.branding.url );
        localStorage.setItem('primary_color', response.data.branding.primary_color);
        localStorage.setItem('business_name', response.data.organization_name);
        localStorage.setItem('allow_cancelations', response.data.permissions.allow_cancelations);
        localStorage.setItem('org', response.data.org_handle);

    }
  }).catch((err)=>{
    console.log("Error retirving org")
    self.setState({
      snackOpen: true,
      snackVariant:"error",
      snackMessage:`Could not load branding. Be sure ${self.props.match.params.org} is the place you want to log into.`
    })
  })
}

  handleChange = name => event => {
   this.setState({
     [name]: event.target.value,
   });
 };
 handleSnackClose = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }

   this.setState({ snackOpen: false });
 };

  handleClick(event){
    console.log(this.state)
    event.preventDefault();
    var self = this;

    var payload={
      "username":this.state.username.toLowerCase(),
      "password":this.state.password,
    }
    axios.post(`${apiBaseUrl}/v1/user/${self.props.match.params.org}/login`, payload)
    .then(function (response) {
      if(response.status === 200){
        if (response.data.email_verified ) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('timestamp', new Date().getTime());
          localStorage.setItem('name', `${response.data.first_name} ${response.data.last_name}`);
          localStorage.setItem('first_name', response.data.first_name);
          localStorage.setItem('last_name', response.data.last_name);
          localStorage.setItem('_id', response.data._id);
          localStorage.setItem('gravatar', `https://www.gravatar.com/avatar/${response.data.gravatar}?d=mp`);
          localStorage.setItem('org', response.data.org);
          let path = `/customers`
          self.props.history.push(path);
        }else{
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('timestamp', new Date().getTime());
          localStorage.setItem('name', `${response.data.first_name} ${response.data.last_name}`);
          localStorage.setItem('first_name', response.data.first_name);
          localStorage.setItem('last_name', response.data.last_name);
          localStorage.setItem('_id', response.data._id);
          localStorage.setItem('gravatar', `https://www.gravatar.com/avatar/${response.data.gravatar}?d=mp`);
          localStorage.setItem('org', response.data.org);
          console.log(response.data)
          let path = `/verify/${response.data._id}`
          self.props.history.push(path);
        }

      }
      })
    .catch(function (error) {
      console.log(error);
      self.setState(
        {
          usernameMessage:"Incorrect Username or Password",
          passwordMessage:"Incorrect Username or Password",
          usernameInvalid: true,
          passwordInvalid: true,
          snackOpen: true,
          snackVariant:"error",
          snackMessage:"Incorrect username or password."
      })
    });

}

  render() {
    const { classes } = this.props;
    return (
    <div className={classes.background}>
    <Grid container className={classes.root} justify={"center"}>

      <Paper className={classes.paper} elevation={4}>
      <Grid container justify="center">
      <div style={{maxHeight: "3%", marginTop: "1%"}}>
      {localStorage.getItem('logo') ?
        <img src={localStorage.getItem('logo')} style={{maxHeight: "120px"}} alt="logo"/>
        :
        <img src={this.state.logo} style={{maxHeight: "120px"}} alt="logo"/>

      }
      </div>
      </Grid>

      <form className={classes.container} onSubmit={(event) => this.handleClick(event)}>
        <TextField
        label="Email"
        fullWidth
        required
        error={this.state.usernameInvalid}
        className={classes.textField}
        helperText={this.state.usernameMessage}
        onChange={this.handleChange('username')}
        />

        <TextField
        label="Password"
        fullWidth
        required
        error={this.state.passwordInvalid}
        type="password"
        className={classes.textField}
        helperText={this.state.passwordMessage}
        onChange={this.handleChange('password')}
        />

        <Grid container justify={"center"}>
        <br />
        <Button
        variant="contained"
        color="primary"
        fullWidth="true"
        style={{
          backgroundColor: this.state.primary_color || undefined
        }}
        className={classes.button}
        type="submit"
        >
        Login
        </Button>
        </Grid>

        </form>
        <a style={{fontSize: 12, paddingLeft: 10}} href={`/${this.props.match.params.org}/login/link`}>Forgot your password?</a>
        <br/>
        <a style={{fontSize: 12, paddingLeft: 10}} href={`/${this.props.match.params.org}/login/link`}>Don't have a profile yet?</a>
      </Paper>
    </Grid>
    <Snackbar
       anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'center',
       }}
       open={this.state.snackOpen}
       autoHideDuration={3000}
       onClose={this.handleSnackClose}
       >
         <MySnackbarContentWrapper
         variant={this.state.snackVariant}
         message={this.state.snackMessage}
         onClose={this.handleSnackClose}
        />
    </Snackbar>
    </div>
  );
}
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Login);
