import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { Paper, Typography } from "@material-ui/core";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3
  })
});

const SubscriptionSkeleton = () => (
  <Grid container spacing={16} justify="center" alignItems="center">
    <Grid item xs={12} md={8}>
      <Paper className={styles.card}>
        <Typography>Account</Typography>
        <Typography>Membership</Typography>
      </Paper>
    </Grid>
  </Grid>
);

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(SubscriptionSkeleton);
