import Paper from '@material-ui/core/Paper';
import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import queryString from 'qs';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  root:{
    marginTop:15,
    width: "50%",
    marginLeft: "25%"
  },
  background:{
    //backgroundColor: "grey"
  },
  paper: theme.mixins.gutters({
    paddingTop: 20,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  container: {
   display: 'flex',
   flexWrap: 'wrap',
 },
 textField: {
   marginLeft: theme.spacing.unit,
   marginRight: theme.spacing.unit,
 },
 textFieldHalf:{
   marginLeft: theme.spacing.unit * .5,
   marginRight: theme.spacing.unit * .5,
   width: "48%"
 },
 button: {
   //width:00,
    margin: theme.spacing.unit,
  },
  bigAvatar: {
  width: 100,
  height: 100,
  },
 menu: {
   width: 100,
 },
});


class EmailVerification extends React.Component {
  constructor(props){
    super(props);
    this.state={
      username:'test',
      password:'',
      messageError:'',
      messageSuccess:''
    }
  }
  componentDidMount(){
    const query = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (query.code) {
      this.validateCode(query.code)
    }else{
      this.sendEmail()
    }
  }

  handleChange = name => event => {
   this.setState({
     [name]: event.target.value,
   });
 };

 handleSnackClose = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   this.setState({ snackOpen: false });
 };

 validateCode = (code) => {
   var self = this;
   axios({
    method: 'post',
    url: `${apiBaseUrl}/v1/user/verify-email/${code}`,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
  }).then(function (response) {
     console.log(response);
     if(response.status === 200){
       self.setState({
         snackOpen: true,
         snackVariant:"success",
         snackMessage:"Your email has been verified. You will be redirected shortly."
       })
       self.exchangeToken()
     }
   }).catch((err)=>{
     console.log("Error retirving org")
     let path = `/login`
     self.props.history.push(path);
   })
 }

exchangeToken = () => {
   var self = this;
   axios({
    method: 'post',
    url: `${apiBaseUrl}/v1/user/exchange`,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
  }).then(function (response) {
     console.log(response);
     if(response.status === 200){
       localStorage.setItem('token', response.data.token);
     }
     let path = `/customers`
     self.props.history.push(path);
   }).catch((err)=>{
     console.log("Error retirving org")
     let path = `/login`
     self.props.history.push(path);
   })
 }


 sendEmail = () => {
   var self = this;
   axios({
    method: 'post',
    url: `${apiBaseUrl}/v1/user/email`,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type':'application/json'
    },
    data:{
      "email_type": "verificationEmail"
    }
  }).then(function (response) {
     if(response.status === 200){
       self.setState({
         snackOpen: true,
         snackVariant:"success",
         snackMessage:"You should recieve an email shortly."
       })
     }
   }).catch((err)=>{
     console.log("Error sending email")
     let path = `/login`
     self.props.history.push(path);
   })
 }

  handleClick(event){
    let path = `/profile`
    this.props.history.push(path);
}

  render() {
    const { classes } = this.props;
    return (
    <div className={classes.background}>
    <Grid container className={classes.root} justify={"center"}>

      <Paper className={classes.paper} elevation={4}>
      <Grid container justify={"center"}>
      <h2>Email Verification</h2>
      </Grid>

        <Typography>
        You should recieve an email shortly. Please click the link inside to verify
        </Typography>
        <Grid container justify={"center"}>
        <Button
        variant="contained"
        color="primary"

        className={classes.button}
        onClick={(event) => this.sendEmail()}
        >
        Resend Email
        </Button>
        </Grid>

      </Paper>


    </Grid>
    <Snackbar
       anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'center',
       }}
       open={this.state.snackOpen}
       autoHideDuration={3000}
       onClose={this.handleSnackClose}
       >
         <MySnackbarContentWrapper
         variant={this.state.snackVariant}
         message={this.state.snackMessage}
         onClose={this.handleSnackClose}
        />
    </Snackbar>
    </div>
  );
}
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(EmailVerification);
