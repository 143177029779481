import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import NavBar from "../../components/navigation/NavBar";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SubscriptionSkeleton from "../skeletons/subscription";
import { Paper, Typography } from "@material-ui/core";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({
  card: {
    minHeight: 200,
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit
  },
  cardSectionHeader: {
    marginBottom: "-20px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  paginationButton: {
    margin: theme.spacing.unit
  },
  manageButton: {
    float: "right",
    marginTop: 5
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0 // So the Typography noWrap works
  },
  subHeader: {
    paddingBottom: "0px",
    marginBottom: "0px"
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3
  })
});

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {}
    };
  }

  changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  nextPage = event => {
    this.loadCustomers(
      Number(this.state.page) + 1,
      Number(this.state.rowsPerPage)
    );
    this.setState({
      page: this.state.page + 1
    });
  };

  previousPage = event => {
    this.loadCustomers(
      Number(this.state.page) - 1,
      Number(this.state.rowsPerPage)
    );
    this.setState({
      page: this.state.page - 1
    });
  };

  componentDidMount(event) {
    console.log(this.state);
    this.loadSubscription();
  }

  loadInvoices = customer_id => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/user/invoices/${customer_id}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        if (response.status === 200) {
          let newInvoices = [];
          newInvoices = self.state.invoices.slice(); //creates a new array to preserve immutability
          for (let returnedInvoice of response.data.data) {
            newInvoices.push(returnedInvoice);
          }
          console.log("newInvoice", newInvoices);
          self.setState({ invoices: newInvoices });
        }
      })
      .catch(function(error) {
        console.log(error);
        let path = `/login`;
        self.props.history.push(path);
      });
  };

  loadSubscription = () => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/user/subscriptions/${self.props.match.params.customer_id}/${self.props.match.params.subscription_id}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        console.log(response.data);
        if (response.status === 200) {
          const { subscriptions } = response.data;
          self.setState({
            customer: response.data,
            subscription: subscriptions.data.find(
              subscription =>
                subscription.id === self.props.match.params.subscription_id
            )
          });

          /*  for (let customer of self.state.customers) {
        self.loadInvoices(customer.id)
      }*/
        } else if (response.status === 401) {
          let path = `/login`;
          this.props.history.push(path);
        }
      })
      .catch(function(error) {
        console.log(error);
        //  let path = `/login`
        //self.props.history.push(path);
      });
  };

  render() {
    const { classes } = this.props;
    let subJsx = "";
    let lineItems = [];
    let total = 0;
    const { subscription, customer } = this.state;
    if (this.state.subscription) {
      subJsx = [
        <CardHeader
          avatar={
            <Avatar aria-label="Subscription" className={classes.avatar}>
              S
            </Avatar>
          }
          action={
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          }
          title={"Subscription - " + subscription.id}
          subheader={"Customer - " + customer.email}
        />
      ];

      for (let item of subscription.items.data) {
        total = total + (item.plan.amount / 100) * item.quantity;
        console.log(item);
        lineItems.push(
          <div>
            <CardContent>
              <List>
                <ListItem>
                  <ListItemText primary={item.full_product.name} />
                  <ListItemText
                    primary={
                      "$" +
                      Number(item.plan.amount / 100).toFixed(2) +
                      " x " +
                      item.quantity
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="SubTotal" />
                  <ListItemText
                    primary={
                      "$" +
                      Number((item.plan.amount / 100) * item.quantity).toFixed(
                        2
                      )
                    }
                  />
                </ListItem>
              </List>
            </CardContent>
            <Divider />
          </div>
        );
      }
    }

    return (
      <div>
        <NavBar {...this.props} />

        <Grid container spacing={16} justify="center" alignItems="center">
          <Grid item xs={12} md={8}>
            <Paper className={classes.card}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3">Product Name</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Plan Details</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <ButtonGroup
                    variant="outlined"
                    aria-label="outlined primary button group"
                  > */}
                  <Button size="small" variant="text">Apply Coupon</Button>
                  <Button size="small" variant="text">Change Plan</Button>
                  <Button size="small" variant="text">Pause</Button>
                  <Button size="small" variant="text">Cancel</Button>
                  {/* </ButtonGroup> */}
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <table>
                    <tr>
                      <td>Status</td>
                      <td>Active</td>
                    </tr>
                    <tr>
                      <td>Current Balance</td>
                      <td>$50.00</td>
                    </tr>
                    <tr>
                      <td>Next Billing Amount</td>
                      <td>$100</td>
                    </tr>
                  </table>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Billing Details</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="text">Update Payment Method</Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Billing Address</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="text">Edit Billing Address</Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Shipping Address</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="text">Edit Shipping Address</Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Subscription);
