import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import queryString from "qs";
import { Paper } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import GoogleLogin from "react-google-login";
import cogoToast from "cogo-toast";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { format } from "date-fns";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import googleIcon from "../../assets/googleIcon.png";
import "../../App.css";

//import mockCustomers from "./mockCustomers";

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="#8551fd">
        Secured by InstaPayments
      </Link>
    </Typography>
  );
}

const styles = (theme) => ({
  paper: {},
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleButton: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  brandIcon: {
    maxHeight: 25,
    marginRight: 10,
  },
  formBackground: {
    padding: "20px",
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  customerPaper: {
    minHeight: 40,
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});

const LoginStripePortal = (props) => {
  const query = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const { classes } = props;
  const org_handle = props.match.params.org;

  const [state, setState] = useState({});
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    localStorage.setItem("org", org_handle);
  }, [org_handle]);
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/v1/org/${org_handle}/brand`)
      .then(function (response) {
        if (response.status === 200 || response.status === 304) {
          setState({
            ...response.data.branding,
          });

          localStorage.setItem(
            "logo",
            response.data.branding.logo || "/images/icon.png"
          );
          localStorage.setItem("url", response.data.branding.url);
          localStorage.setItem(
            "primary_color",
            response.data.branding.primary_color
          );
          localStorage.setItem(
            "business_name",
            response.data.organization_name
          );
          localStorage.setItem(
            "allow_cancelations",
            response.data.permissions.allow_cancelations
          );
        }
      })
      .catch((err) => {
        console.log("Error retrieving org");
        setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage: `Could not load branding. Be sure ${props.match.params.org} is the place you want to log into.`,
        });
      });

    if (query.session) {
      handleSessionCode(query.session);
    }
  }, []);

  const handleSessionCode = async (session) => {
    console.log(session);
    let { data } = await axios({
      url: `${apiBaseUrl}/v1/user/${org_handle}/session/${session}`,
      method: "GET",
    });
    localStorage.setItem("token", data.token);
    localStorage.setItem("timestamp", new Date().getTime());
    props.history.push("/dashboard");
  };

  const handleGoogleLogin = (googleUser) => {
    const payload = {
      googleToken: googleUser.tokenObj.id_token,
      orgHandle: org_handle,
    };
    axios
      .post(`${apiBaseUrl}/v1/user/login/google`, payload)
      .then(function ({ data }) {
        if (data.user?.customers?.length === 0) {
          cogoToast.error(
            "We weren't able to find a customer with that email address. Are you sure the email is what you used to make a purchase?",
            {
              hideAfter: 10,
            }
          );
        }
        if (data.user?.customers?.length !== 0) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("timestamp", new Date().getTime());
          props.history.push("/dashboard");
        }
      })
      .catch(function (error) {
        console.log(error);
        cogoToast.error(
          error?.response?.data?.message ||
            "This google profile or email was not found.",
          {
            hideAfter: 10,
          }
        );
      });
  };
  const handleGoogleLoginFailure = (err) => {
    console.log(err);
  };

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(state);

    const payload = {
      email: state.username,
      password: state.password,
    };
    axios
      .post(`${apiBaseUrl}/v1/user/${org_handle}/login/link/stripe`, payload)
      .then(function (response) {
        console.log(response);
        cogoToast.success("Check your email for a link to log in.");
      })
      .catch(function (error) {
        console.log(error);
        let { hide } = cogoToast.error(
          error?.response?.data?.message || "Error logging in.",
          {
            hideAfter: 10,
            onClick: () => {
              hide();
            },
          }
        );
        setState({
          ...state,
          usernameMessage: "Incorrect Username or Password",
          passwordMessage: "Incorrect Username or Password",
          usernameInvalid: true,
          passwordInvalid: true,
        });
      });
  };

  const storeValues = (response) => {
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("timestamp", new Date().getTime());
    localStorage.setItem(
      "name",
      `${response.data.user.first_name} ${response.data.user.last_name}`
    );
    localStorage.setItem("first_name", response.data.user.first_name);
    localStorage.setItem("last_name", response.data.user.last_name);
    localStorage.setItem("user_id", response.data._id);
    localStorage.setItem("gravatar", response.data.gravatar);

    let path = `/dashboard`;
    props.history.push(path);
  };

  return (
    <Container component="main" maxWidth="sm">
      {customers.length === 0 ? (
        <Paper className={classes.formBackground}>
          <CssBaseline />

          {localStorage.getItem("logo") ? (
            <img
              src={localStorage.getItem("logo")}
              style={{ maxHeight: "120px" }}
              alt="logo"
            />
          ) : (
            <img src={state.logo} style={{ maxHeight: "120px" }} alt="logo" />
          )}
          <div className={classes.googleButton}>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Log In with Google"
              theme="dark"
              onSuccess={handleGoogleLogin}
              onFailure={handleGoogleLoginFailure}
              className="login-google-btn"
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => (
                <Button
                  variant={"contained"}
                  fullWidth
                  //disabled={disabled}
                  color="primary"
                  //style={buttonStyles}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img
                    key="googlebutton"
                    className={classes.brandIcon}
                    src={googleIcon}
                    alt="Google Logo"
                  />
                  Log In With Google
                </Button>
              )}
            />
          </div>
          <Grid
            container
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={5}>
              <Divider />
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              <Typography variant="h6" style={{ fontWeight: 500 }}>
                OR
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Divider />
            </Grid>
          </Grid>
          <Typography component="h1" variant="h5"></Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              autoFocus
              onChange={handleChange("username")}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              placeholder="Enter the email used to make your purchase."
              autoComplete="email"
              InputLabelProps={{ shrink: true }}
            />
            {/* <TextField
            variant="outlined"
            margin="normal"
            onChange={handleChange("password")}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          /> */}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Verify my Email and Log In
            </Button>
            {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Login with password
              </Link>
            </Grid>
          </Grid> */}
          </form>

          <Box mt={4}>
            <Copyright />
          </Box>
        </Paper>
      ) : (
        <Grid container justify="center" spacing={2}>
          {localStorage.getItem("logo") ? (
            <img
              src={localStorage.getItem("logo")}
              style={{ maxHeight: "120px", marginTop: "20px" }}
              alt="logo"
            />
          ) : (
            <img
              src={state.logo}
              style={{ maxHeight: "120px", marginTop: "20px" }}
              alt="logo"
            />
          )}
          <Grid container item xs={12} justify="center">
            <Typography variant="h6">Pick a Customer</Typography>
          </Grid>
          <Grid container item xs={12} justify="center">
            <Typography variant="body1">
              You have multiple customer profiles with us. Which one would you
              like to manage?
            </Typography>
          </Grid>
          {customers.map((customer) => (
            <Grid item xs={12}>
              <Paper className={classes.customerPaper}>
                <Grid
                  container
                  justify="space-between"
                  style={{ width: "100%" }}
                >
                  {customer.name && (
                    <Grid item xs={2}>
                      <Typography>Name</Typography>
                      <br />
                      {customer.name}
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    <Typography> Created At</Typography>
                    <br />
                    {format(new Date(customer.created), "m/dd/yyyy")}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography> Sub Count</Typography>
                    <br />
                    {customer.subscriptions?.total_count || 0}
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      href={customer.portalSession.url}
                      variant="contained"
                    >
                      Manage
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default withStyles(styles)(LoginStripePortal);
