import React from "react";
import axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavBar from "../../components/navigation/NavBar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CreditCard from "@material-ui/icons/CreditCard";
import AutoRenew from "@material-ui/icons/Autorenew";
import Invoice from "@material-ui/icons/Description";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DiscountIcon from "@material-ui/icons/MoneyOff";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    //flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "block"
  },
  card: {
    //maxWidth: 700,
    width: "100%",
    //margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit
  },
  cardSectionHeader: {
    //marginBottom: "-20px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  paginationButton: {
    margin: theme.spacing.unit
  },
  manageButton: {
    float: "right",
    marginTop: 5
  },
  content: {
    //flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    //padding: theme.spacing.unit * 3,
    minWidth: 0 // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar
});

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      customers: [],
      invoices: [],
      products: [],
      dialogOpen: false,
      dialogSub: "",
      fullDialogSub: {},
      tabValue: 0,
      updateSubComplete: true,
      cancelSubComplete: true,
      snackOpen: false,
      snackVariant: "success",
      snackMessage: "Password has been updated",
      allow_cancelations: true
    };
  }

  changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`;
    this.props.history.push(path);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  handleTabChange = (event, value) => {
    this.setState({ tabValue: value });
  };

  setDialogSub = (id, subscription) => {
    this.setState({
      dialogSub: id,
      dialogOpen: true,
      fullDialogSub: subscription
    });
  };
  unsetDialogSub = event => {
    this.setState({ dialogSub: "", dialogOpen: false, fullDialogSub: {} });
  };

  componentDidMount(event) {
    this.loadCustomers();
  }

  loadInvoices = customer_id => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/user/invoices/${customer_id}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        if (response.status === 200) {
          let newInvoices = [];
          newInvoices = self.state.invoices.slice(); //creates a new array to preserve immutability
          for (let returnedInvoice of response.data.data) {
            newInvoices.push(returnedInvoice);
          }
          self.setState({ invoices: newInvoices });
        }
      })
      .catch(function(error) {
        console.log(error);
        let path = `/login`;
        self.props.history.push(path);
      });
  };

  cancelSub = () => {
    this.setState({ cancelSubComplete: false });
    var self = this;
    axios({
      method: "delete",
      url: `${apiBaseUrl}/v1/user/subscriptions/${self.state.dialogSub}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        self.setState({
          snackOpen: true,
          snackVariant: "success",
          snackMessage: "Your subscription has been set to canceled. "
        });
        self.unsetDialogSub();
        setTimeout(function() {
          self.loadCustomers();
        }, 5000);
      })
      .catch(function(error) {
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage: "There was an error trying to cancel your subscription."
        });
        console.log(error);
        //let path = `/login`
        //self.props.history.push(path);
      });
  };

  fetchProducts = arrayOfProducts => {
    console.log("fetch products was called");
    var self = this;
    if (!arrayOfProducts.length < 1) {
      axios({
        method: "post",
        url: `${apiBaseUrl}/v1/org/products`,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        data: {
          products: arrayOfProducts
        }
      })
        .then(function(response) {
          console.log("fetch products response", response);
          self.setState({
            products: response.data
          });
          //self.loadCustomers()
        })
        .catch(function(error) {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: "There was an error trying to fetch your products."
          });
        });
    } else {
      console.log(
        "No products were passed to fetch products. This usually means there are no active subscriptions on the customer(s)"
      );
    }
  };

  reactivateSub = () => {
    this.setState({ reactivateSubComplete: false });
    var self = this;
    axios({
      method: "put",
      url: `${apiBaseUrl}/v1/user/subscriptions/${self.state.dialogSub}/reactivate`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        self.setState({
          snackOpen: true,
          snackVariant: "success",
          snackMessage: "Your subscription has been reactivated."
        });
        self.unsetDialogSub();
        setTimeout(function() {
          self.loadCustomers();
        }, 5000);
      })
      .catch(function(error) {
        self.setState({
          snackOpen: true,
          snackVariant: "error",
          snackMessage:
            "There was an error trying to reactivate your subscription."
        });
        //let path = `/login`
        //self.props.history.push(path);
      });
  };

  loadCustomers = () => {
    var self = this;
    axios({
      method: "get",
      url: `${apiBaseUrl}/v1/user/me?customers=true`,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
      .then(function(response) {
        if (response.status === 200) {
          const { sources, default_source } = response.data;
          if (default_source) {
            //const payment_method = sources.data.filter(source => source.id === default_source);
          } else if (sources) {
          }
          self.setState({
            user: response.data,
            customers: response.data.customers
            //invoice: response.data.subscriptions,
          });
          const productArray = [];
          console.log("products length", self.state.products.length);
          if (self.state.products.length < 1) {
            for (let customer of self.state.customers) {
              console.log("customers", self.state.customers.length);
              for (let subscription of customer.subscriptions.data) {
                console.log(
                  "subscriptions",
                  customer.subscriptions.data.length
                );
                for (let item of subscription.items.data) {
                  console.log("items", subscription.items.data.length);
                  productArray.push(item.plan.product);
                }
              }
            }
            self.fetchProducts(productArray);
          }
          console.log(productArray);
          if (self.state.invoices.length < 1) {
            for (let customer of self.state.customers) {
              self.loadInvoices(customer.id);
            }
          }
        } else if (response.status === 401) {
          let path = `/login`;
          this.props.history.push(path);
        }
      })
      .catch(function(error) {
        console.log(error);
        let path = `/login`;
        self.props.history.push(path);
      });
  };

  retrieveName = searchProduct => {
    console.log(searchProduct);
    for (let product of this.state.products) {
      console.log(product.id, searchProduct);
      if (product.id === searchProduct) {
        console.log("it was true");
        return product.name;
      }
    }
  };

  render() {
    const { classes, ...other } = this.props;
    const customers = [];
    console.log(this.state);

    const dialogLineItems = [];
    if (this.state.dialogSub) {
      console.log(this.state.fullDialogSub.items.data);

      for (let lineItem of this.state.fullDialogSub.items.data) {
        dialogLineItems.push(
          <TableRow key={lineItem.id}>
            <TableCell>{this.retrieveName(lineItem.plan.product)}</TableCell>
            <TableCell>{`${lineItem.quantity}`}</TableCell>
            <TableCell>{`$${(lineItem.plan.amount / 100).toFixed(
              2
            )}`}</TableCell>
          </TableRow>
        );
      }
    }

    for (let customer of this.state.customers) {
      //loop through the customers returned
      let discount = [];
      const invoices = [];
      const arrayOfSubscriptions = [];
      //const payment_method ={};
      const filteredInvoices = this.state.invoices.filter(
        invoice => invoice.customer === customer.id
      );
      const { subscriptions, sources, default_source } = customer;

      if (default_source) {
        const payment_method = sources.data.filter(
          source => source.id === default_source
        );
        customer.default_payment_method = payment_method[0];
      } else if (sources && sources.data.length > 0) {
        customer.default_payment_method = sources.data[0];
      }
      if (customer.discount != null) {
        let coupon = customer.discount.coupon;
        let discount_text = coupon.amount_off
          ? "$" + coupon.amount_off + " Off"
          : coupon.percent_off + "% Off";
        let discount_end = customer.discount.end
          ? moment(new Date(customer.discount.end * 1000)).format("MM/DD/YYYY")
          : "Expiration: not set";
        let discount_code = "Code: " + coupon.id;
        discount = (
          <div>
            <CardHeader
              subheader="Discount"
              className={classes.cardSectionHeader}
            />
            <Typography noWrap={true}>
              <IconButton disabled={true}>
                <DiscountIcon color="primary" />
              </IconButton>
              {discount_text + " - " + discount_end + " - " + discount_code}
            </Typography>
          </div>
        );
      }

      for (let subscription of subscriptions.data) {
        const dateFormatted = moment(
          new Date(subscription.current_period_end * 1000)
        ).format("MM/DD/YYYY");

        const filteredProduct = this.state.products.filter(product => {
          return product.id === subscription.items.data[0].plan.product;
        });
        let productName = "";
        if (filteredProduct.length > 0) {
          productName = filteredProduct[0].name;
        }

        arrayOfSubscriptions.push(
          <div key={subscription.id}>
            <Typography component="p" noWrap={true}>
              <IconButton>
                <AutoRenew color="primary" />
              </IconButton>
              {subscription.cancel_at_period_end
                ? `Status: Set to cancel on ${dateFormatted} `
                : `${productName} - Status: ${subscription.status} - Next Bill: ${dateFormatted} `}

              {localStorage.getItem("allow_cancelations") ? (
                <Button
                  className={classes.manageButton}
                  variant="outlined"
                  color="primary"
                  size="small"
                  id={subscription.id}
                  onClick={() =>
                    this.setDialogSub(subscription.id, subscription)
                  }
                >
                  Manage
                </Button>
              ) : null}
            </Typography>
          </div>
        );
      }

      for (let invoice of filteredInvoices) {
        invoices.push(
          <div key={invoice.id}>
            <Typography noWrap={true}>
              <IconButton href={invoice.hosted_invoice_url} target="_blank">
                <Invoice color="primary" />
              </IconButton>
              {moment(new Date(invoice.date * 1000)).format("MM/DD/YYYY")} |{" "}
              {`Amount Due:  $${Number(invoice.amount_due / 100).toFixed(
                2
              )} | Amount Paid: $${Number(invoice.amount_paid / 100).toFixed(
                2
              )}`}
              <Button
                className={classes.manageButton}
                variant="outlined"
                color="primary"
                size="small"
                href={invoice.hosted_invoice_url}
                target="_blank"
              >
                View
              </Button>
            </Typography>
          </div>
        );
      }

      customers.push(
        <Grid item xs={12} md={8}>
          <Card className={classes.card} key={customer.id}>
            {/* CUSTOMER HEADER BEG*/}
            <CardHeader
              avatar={
                <Avatar aria-label="Customer" className={classes.avatar}>
                  C
                </Avatar>
              }
              title={"Customer - " + customer.email + " - " + customer.id}
              subheader={
                customer.current_period_end
                  ? "Next Billing " +
                    moment(new Date(customer.current_period_end * 1000)).format(
                      "MM DD YYYY"
                    )
                  : null
              }
            />
            <Divider />
            {/* CUSTOMER HEADER END*/}
            {arrayOfSubscriptions.length > 0 ? (
              <CardHeader
                className={classes.cardSectionHeader}
                subheader={`Subscriptions`}
              />
            ) : null}
            {arrayOfSubscriptions}
            <Divider />
            {/* CUSTOMER SUBSCRIPTION END*/}
            {discount}
            <Divider />

            {/* PAYMENT METHOD BEG*/}
            {customer.default_payment_method ? (
              <CardHeader
                subheader="Payment Method"
                className={classes.cardSectionHeader}
              />
            ) : null}

            <Typography>
              <IconButton
                target="_blank"
                href={`https://forms.instapayments.io${customer.update_url}`}
              >
                <CreditCard color="primary" />
              </IconButton>
              {customer.default_payment_method
                ? customer.default_payment_method &&
                  customer.default_payment_method.name +
                    " " +
                    customer.default_payment_method.brand +
                    " " +
                    " ********* " +
                    customer.default_payment_method.last4 +
                    "  " +
                    customer.default_payment_method.exp_month +
                    "/" +
                    customer.default_payment_method.exp_year
                : "Add a payment method"}
              <Button
                className={classes.manageButton}
                variant="outlined"
                color="primary"
                size="small"
                href={`https://forms.instapayments.io${customer.update_url}`}
                target="_blank"
              >
                {customer.default_payment_method ? "Update" : "Add Card"}
              </Button>
            </Typography>
            {/* PAYMENT METHOD END*/}

            <Divider />
            {invoices.length > 0 ? (
              <CardHeader
                className={classes.cardSectionHeader}
                subheader="Latest Invoices"
              />
            ) : null}
            {invoices}
          </Card>
        </Grid>
      );
    }

    return (
      <div className={classes.root}>
        <NavBar {...other} />
    
          <div style={{ paddingRight: 20 }}>
            <Grid container spacing={3} justify="center" alignItems="center">
              {customers}

              <Dialog
                open={this.state.dialogOpen}
                fullWidth={true}
                onClose={this.unsetDialogSub}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  <AppBar position="static" color="default">
                    <Tabs
                      value={this.state.tabValue}
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      fullWidth
                    >
                      <Tab label="Line Items" />
                      <Tab
                        label={
                          this.state.fullDialogSub.cancel_at_period_end
                            ? `Reactivate`
                            : `Cancel`
                        }
                      />
                    </Tabs>
                  </AppBar>
                </DialogTitle>

                {this.state.tabValue === 0 && (
                  <TabContainer>
                    <DialogContent>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Product</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>{dialogLineItems}</TableBody>
                      </Table>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.unsetDialogSub} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </TabContainer>
                )}

                {this.state.tabValue === 1 && (
                  <TabContainer>
                    <DialogContent>
                      {this.state.fullDialogSub.cancel_at_period_end
                        ? `Reactivate subscription ${this.state.dialogSub} ? It may take a few minutes to show the subscription as reactivated.`
                        : `Are you sure you want to cancel subscription ${this.state.dialogSub} ? It may take a few minutes to show the cancelation.`}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.unsetDialogSub} color="primary">
                        Close
                      </Button>
                      <Button
                        onClick={
                          this.state.fullDialogSub.cancel_at_period_end
                            ? this.reactivateSub
                            : this.cancelSub
                        }
                        color="primary"
                        disabled={!this.state.cancelSubComplete}
                      >
                        {this.state.fullDialogSub.cancel_at_period_end
                          ? `Reactivate`
                          : `Cancel Sub`}
                      </Button>
                    </DialogActions>
                  </TabContainer>
                )}
              </Dialog>
            </Grid>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            open={this.state.snackOpen}
            autoHideDuration={3000}
            onClose={this.handleSnackClose}
          >
            <MySnackbarContentWrapper
              variant={this.state.snackVariant}
              message={this.state.snackMessage}
              onClose={this.handleSnackClose}
            />
          </Snackbar>
      </div>
    );
  }
}

/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Customer);
